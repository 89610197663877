import './NavigationSidebar.scss';

import { useLocation, useNavigate } from 'react-router';

import Dropdown from '../../common/dropdowns/Dropdown';
import Sidebar from './Sidebar';
import { SIDEBAR_NAVIGATION_ITEMS, NavigationItemKey, SubNavigationItemKey, NavigationItem } from './NavigationSidebarConstants';
import AccordionButton from '../../common/buttons/AccordionButton';
import NavItemContent from './NavItemContent';
import NavSubItem from './NavSubItem';
import useAuth from '../../../context/AuthContext';
import OutlineButton from '../../common/buttons/OutlineButton';

interface NavigationSidebarProps {
  sidebarNavigationItems?: NavigationItem[];
  showSidebarHeader?: boolean;
}

function getCounts(key: NavigationItemKey): number | null {
  switch (key) {
    default:
      return null;
  }
}

function getSubCounts(key: SubNavigationItemKey): number | null {
  switch (key) {
    default:
      return null;
  }
}

function NavigationSidebar({ sidebarNavigationItems = SIDEBAR_NAVIGATION_ITEMS } : Readonly<NavigationSidebarProps>) {
  const { pathname } = useLocation();
  const { user, selectedBusinessId, selectBusinessId, logout } = useAuth();
  const businesses = user?.businesses || [];
  const navigate = useNavigate();
  const selectedNavOption = sidebarNavigationItems.find(({ prefix }) => pathname.startsWith(prefix));
  const selectedSubNavItem = selectedNavOption ? selectedNavOption.subItems.find(({ href }) => pathname === href) : null;
  const selectedNavKey = selectedNavOption ? selectedNavOption.key : null;
  const selectedSubNavKey = selectedSubNavItem ? selectedSubNavItem.key : null;
  const navItems = sidebarNavigationItems.map(({ key, displayText, icon, selectedIcon, href, subItems, hasBottomDivider = false }) => {
    const onClick = () => {
      navigate(href);
    }
    const count = getCounts(key);
    const selected = selectedNavKey === key;
    const buttonContent = <NavItemContent icon={icon} selectedIcon={selectedIcon} text={displayText} selected={selected} count={count} />;
    const navSubItems = subItems.map(({ key, displayText, href }) => {
      const onClick = () => {
        navigate(href);
      }
      const count = getSubCounts(key);
      const selected = selectedSubNavKey === key;
      return <NavSubItem key={key} text={displayText} selected={selected} onClick={onClick} count={count} />
    });
    const empty = subItems.length === 0;
    const containerClassNames = ['nav-item-content-container', hasBottomDivider ? 'bottom-divider' : '', empty ? 'empty' : ''].join(' ');
    return (
      <AccordionButton key={key} buttonContent={buttonContent} onClick={onClick} open={selected} additionalClassNames={[containerClassNames]}>
        {navSubItems}
      </AccordionButton>
    )
  });
  const businessOptions = businesses.map(({ _id, businessName }) => ({ displayText: businessName, value: _id }));

  return (
    <Sidebar additionalClassNames={['navigation-sidebar']}>
      <section className={'sidebar-body'}>
      { businesses.length > 0 && (
        <section className={'sidebar-header'}>
          <Dropdown
            options={businessOptions}
            defaultDisplayText={'Select business'}
            selected={selectedBusinessId}
            select={selectBusinessId}
          />
        </section>
      )}
        {navItems}
      </section>
      <section className={'logout-button-container'}>
        <OutlineButton content={'Log out'} onClick={logout} />
      </section>
    </Sidebar>
  )
};

export default NavigationSidebar;