import './RecoverClientsPreview.scss';

import React, { useState, useRef, useEffect } from 'react';

import { RecoverClientMessage } from '../RecoverClientsConstants';

import RecoverClientsPreviewHeader from './RecoverClientsPreviewHeader';
import RecoverClientsPreviewFooter from './RecoverClientsPreviewFooter';
import SmsPreview from './SmsPreview';
import PaginationBar from '../../../common/tables/PaginationBar';
import FineTuneModal from '../modals/FineTuneModal';

interface RecoverClientsPreviewProps {
  previews: RecoverClientMessage[],
  setPreview: (updatedMessage: string, messageIndex: number) => void,
  setAllPreviews: React.Dispatch<React.SetStateAction<RecoverClientMessage[]>>,
  totalMessages: number,
}

const MAX_ROWS_PER_PAGE = 1;

function RecoverClientsPreview({ previews, setPreview, setAllPreviews, totalMessages } : RecoverClientsPreviewProps) {
  const [tuningModalOpen, setTuningModalOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [cursor, setCursor] = useState(0);
  const messageNumber = cursor + 1;

  const openTuningModal = () => setTuningModalOpen(true);
  const closeTuningModal = () => setTuningModalOpen(false);

  const fineTunePrompt = () => {
    save();
    setEditing(false);
    openTuningModal();
  }
  
  const { recipientName, message, sendTo } = previews[cursor];
  console.log(previews[cursor]);
  const target = `${sendTo} (Mobile)`;

  const contentRef = useRef(message);

  const save = () => {
    setPreview(contentRef.current, cursor);
  };

  const saveAndSetCursor = (updatedCursor: number) => {
    save();
    setEditing(false);
    setCursor(updatedCursor);
  }

  const saveFineTunedPreviews = (fineTunedPreviews: RecoverClientMessage[]) => {
    setAllPreviews(fineTunedPreviews);
    closeTuningModal();
  }

  useEffect(() => {
    const { message } = previews[cursor];
    contentRef.current = message;
  }, [previews, cursor]);

  console.log(message);
  const messageDisplay = <SmsPreview content={message} editing={editing} contentRef={contentRef} />
  return (
    <section className={'preview-message-container'}>
      {tuningModalOpen && <FineTuneModal previews={previews} close={closeTuningModal} confirm={saveFineTunedPreviews} />}
      <section className={'preview-message'}>
        <RecoverClientsPreviewHeader name={recipientName} target={target} messageNumber={messageNumber} totalMessages={totalMessages} />
        <section className={'message-container'}>
          {messageDisplay}
        </section>
        <RecoverClientsPreviewFooter editing={editing} setEditing={setEditing} openTuningModal={fineTunePrompt} save={save} />
      </section>
      <PaginationBar cursor={cursor} setCursor={saveAndSetCursor} maxRowsPerPage={MAX_ROWS_PER_PAGE} totalRows={totalMessages} />
    </section>
  );
};

export default RecoverClientsPreview;