import './AddressFilter.scss';

import { useState } from 'react';

import OutlineButton from '../../common/buttons/OutlineButton';
import { AddressFilterType, ADDRESS_FILTER_ATTRIBUTES_MAP } from '../../common/multi-select-rows/filters/FilterConstants';
import LightPurpleTag from '../../common/tags/LightPurpleTag';
import { Address } from '../find-clients/HandwrittenLettersConstants';
import AddAddressFilterModal from './AddAddressFilterModal';

interface AddressFilterProps {
  addresses: Address[] | null,
  filteredAddresses: Address[] | null,
  filters: AddressFilterType[],
  setFilters: React.Dispatch<React.SetStateAction<AddressFilterType[]>>,
  searchText: string,
};

function AddressFilter({ addresses, filteredAddresses, filters, setFilters } : AddressFilterProps) {
  console.log({ addresses, filteredAddresses });
  const resetFilters = () => setFilters([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const filteredAddressCount = addresses && filteredAddresses ? `(${filteredAddresses.length}/${addresses.length})` : '';
  const selectedFilters = filters.length > 0;

  // Not sure how to get around this issue of attribute potentially being null.
  // If I remove the null check I get this error: Type 'null' cannot be used as an index type
  const filterTags = filters
    .map(({ attribute }, index) => {
      if (attribute) {
        return <LightPurpleTag key={`${attribute}-${index}`} content={ADDRESS_FILTER_ATTRIBUTES_MAP[attribute].displayText} />;
      }
      return null;
    })

  const confirmFilters = (filters: AddressFilterType[]) => {
    const populatedFilters = filters.filter((filter: AddressFilterType) => filter.attribute);
    setFilters(populatedFilters);
  };

  const filterText = `Filters${filters.length > 0 ? ` (${filters.length})` : ''}`;
  return (
    <section className={'address-filters'}>
      <OutlineButton additionalClassNames={['filter-button']} content={filterText} onClick={() => setFilterOpen(true)} />
      {filterTags}
      {selectedFilters && <span>{filteredAddressCount}</span>}
      {filterOpen && <AddAddressFilterModal parentFilters={filters} close={() => setFilterOpen(false)} confirm={confirmFilters} resetFilters={resetFilters} />}
    </section>
  );
}

export default AddressFilter;