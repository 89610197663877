import { getData, postData } from './Api';
import { Address } from '../components/case-studies/find-clients/HandwrittenLettersConstants';

const PostCaseStudyUrls = {
  RequestCaseStudyUrl: '/api/case-studies/request',
  RequestManyAddressesForBusinessUrl: '/api/handwritten-letter/request',
  AddressesForBusinessUrl: '/api/handwritten-letter/addresses',
  SendLettersUrl: '/api/handwritten-letter/send',
};

const {
  RequestCaseStudyUrl,
  AddressesForBusinessUrl,
  RequestManyAddressesForBusinessUrl,
  SendLettersUrl,
} = PostCaseStudyUrls;

interface CaseStudyRequest {
  message: string
};

interface RequestHandWrittenLetterAddressRequest {
  addresses: Address[],
  businessId: string,
};

interface RequestHandWrittenLetterAddressRequestResponse {
  success: boolean,
  addresses: Address[]
};

interface HandwrittenLetterAddressResponse {
  addresses: Address[]
};

interface SendLettersRequest {
  addressIds: string[],
};

/**
 * Sends a slack message for a case study request.
 */
export async function postCaseStudyRequest(message: string): Promise<boolean> {
  await postData<CaseStudyRequest, void>(
    RequestCaseStudyUrl, { message }
  );
  return true;
}

/**
 * Fetches all addresses for a particular business id.
 */
export async function getAddressesForBusiness(businessId: string): Promise<HandwrittenLetterAddressResponse> {
  return getData<HandwrittenLetterAddressResponse>(`${AddressesForBusinessUrl}/${businessId}`);
}

/**
 * Requests addresses.
 */
export async function postRequestAddresses(addresses: Address[], businessId: string): Promise<RequestHandWrittenLetterAddressRequestResponse> {
  return await postData<RequestHandWrittenLetterAddressRequest, RequestHandWrittenLetterAddressRequestResponse>(
    RequestManyAddressesForBusinessUrl, { addresses, businessId }
  );
}

/**
 * Updates all addresses to sent.
 */
export async function sendLettersToAddresses(addressIds: string[]): Promise<void> {
  await postData<SendLettersRequest, void>(
    SendLettersUrl, { addressIds }
  );
}
