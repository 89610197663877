import './ConfirmMessageSendModal.scss';

import React from 'react';

import Modal from '../../../common/modals/Modal';
import ConfirmationModalContent from '../../../common/modals/ConfirmationModalContent';

interface ConfirmMessageSendModalProps {
  cancel: () => void,
  confirm: () => void,
};

function ConfirmMessageSendModal({ cancel, confirm } : Readonly<ConfirmMessageSendModalProps>) {
  return (
    <Modal additionalClassNames={['confirm-variant-deletion-modal']}>
      <ConfirmationModalContent
        title={'Send Message'}
        subtitle={'Please confirm you would like to send out your messages.'}
        cancel={cancel}
        confirm={confirm}
      />
    </Modal>
  )
};

export default ConfirmMessageSendModal;