import './RecoverClientsSettingsPage.scss';

import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router';

import PrimaryButton from '../../common/buttons/PrimaryButton';
import PrimaryInput from '../../common/inputs/PrimaryInput';
import GenericSwitch from '../../common/switches/GenericSwitch';
import RecoverClientsPreview from './previews/RecoverClientsPreview';
import { MessageStatus, RecoverClientMessage } from './RecoverClientsConstants';
import { Channel } from './RecoverClientsConstants';

const DEFAULT_FOLLOWUP = {
  _id: '123',
  recipientName: 'Andrew Zhang',
  channel: Channel.SMS,
  sendTo: '0434729991',
  sendDate: Date.now(),
  messageStatus: MessageStatus.NotSent,
  trigger: 'Moved to \'priority to reactivate\' segment',
  triggerDate: Date.now(),
  message: '',
  creationDate: Date.now(),
  businessId: null,
  deletionDate: null,
}

function RecoverClientsSettingsPage(): ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [followUpMessage, setFollowUpMessage] = useState<RecoverClientMessage[]>([DEFAULT_FOLLOWUP]);
  const [clientTypeText, setClientTypeText] = useState('');
  const createRequest = async () => {
    setLoading(true);
    const firstName = 'Lance';
    const businessName = 'WorkRex';
    const message = `${firstName} from ${businessName} uploaded  requested to receover lost clients of type: ${clientTypeText}`;
    // await postCaseStudyRequest(message);
    setLoading(false);
    navigate('/recover-clients/manage');
  };

  const setPreviewMessage = (updatedMessage: string, messageIndex: number) => {
    const newFollowUpMessage = followUpMessage;
    newFollowUpMessage[messageIndex].message = updatedMessage;
    setFollowUpMessage(newFollowUpMessage);
  }

  const disabled = clientTypeText.length === 0;
  return (
    <article className={'recover-clients-settings-page'}>
      <section className={'page'}>
        <header className={'recover-clients-settings-page-header'}>
          <h2 className={'title'}>{'Settings'}</h2>
          <h3 className={'subtitle'}>{'Setup and manage your auto follow ups'}</h3>
        </header>
        <section className={'page-content'}>
          <section className={'section-container'}>
            <RecoverClientsPreview
              previews={followUpMessage}
              setPreview={setPreviewMessage}
              setAllPreviews={setFollowUpMessage}
              totalMessages={followUpMessage.length}
            />
          </section>
          <section className={'section-container'}>
            <h5 className={'input-subtitle'}>{'Send follow up message x days after a message has been sent with no response'}</h5>
            <section className={'input-container'}>
              <PrimaryInput
                text={clientTypeText}
                setText={setClientTypeText}
                inputType={'number'}
                min={'0'}
                placeholder={''}
                id={'number-of-days-input'}
              />
            </section>
            <section className={'section-container'}>
              <h4 className={'input-subtitle'}>{'Only follow up if the user did not reply or opt out.'}</h4>
              <section className={'input-container'}>
                <GenericSwitch checked={checked} setChecked={setChecked}/>
              </section>
            </section>
          </section>
          <PrimaryButton
            content={'Save'}
            onClick={createRequest}
            additionalClassNames={['save-settings-button']}
            disabled={disabled}
            loading={loading}
          />
        </section>
      </section>
    </article>
  );
}

export default RecoverClientsSettingsPage;