import './PaginationBar.scss';

import GhostButton from '../buttons/GhostButton';
import OutlineButton from '../buttons/OutlineButton';

import BACK_CARET from './assets/back-caret.svg';
import NEXT_CARET from './assets/next-caret.svg';
import ELLIPSIS from './assets/ellipsis.svg';

interface PaginationBarProps {
  cursor: number,
  setCursor: ((cursor: number) => void),
  maxRowsPerPage: number,
  totalRows: number,
  maxPaginationCountShown?: number,
}

function PaginationBar({ cursor, setCursor, maxRowsPerPage, totalRows, maxPaginationCountShown = 3 } : Readonly<PaginationBarProps>) {
  const totalPages = Math.ceil(totalRows / maxRowsPerPage);
  if (totalPages <= 1) {
    return null;
  }
  const currentPage = Math.floor(cursor / maxRowsPerPage);
  const goPrevious = () => {
    const newCursor = Math.max(cursor - maxRowsPerPage, 0);
    setCursor(newCursor);
  }
  const goNext = () => {
    const newCursor = Math.min(cursor + maxRowsPerPage, totalRows);
    setCursor(newCursor);
  }
  const pageOffset = currentPage % maxPaginationCountShown;
  const startingPage = currentPage - pageOffset;
  const remainingPages = totalPages - startingPage;
  const hasPreviousButton = currentPage > 0;
  const hasNextButton = currentPage < totalPages - 1;
  const showNextEllipsis = remainingPages > maxPaginationCountShown;
  const showPreviousEllipsis = startingPage >= maxPaginationCountShown;
  const paginationButtons = Array.from(Array(Math.abs(Math.min(maxPaginationCountShown, remainingPages))).keys()).map((value) => {
    const pageNumber = currentPage - pageOffset + value;
    const isCurrentPage = pageNumber === currentPage;
    const onClick = () => {
      const newCursor = pageNumber * maxRowsPerPage;
      setCursor(newCursor);
    }
    if (isCurrentPage) {
      return (
        <OutlineButton
          key={`pagination-${value}`}
          content={pageNumber + 1}
          onClick={onClick}
          disabled={true}
          additionalClassNames={['pagination-button']}
        />
      );
    }
    return (
      <GhostButton
        key={`pagination-${value}`}
        additionalClassNames={['pagination-button']} 
        content={pageNumber + 1}
        onClick={onClick}
      />
    );
  });
  const previousButtonContent = (
    <span className={'button-content'}>
      <img className={'button-icon'} src={BACK_CARET} alt={'Previous'} />
      <span>{'Previous'}</span>
    </span>
  );
  const nextButtonContent = (
    <span className={'button-content'}>
      <span>{'Next'}</span>
      <img className={'button-icon'} src={NEXT_CARET} alt={'Next'} />
    </span>
  )
  return (
    <aside className={'pagination-bar'}>
      <span className={'left-button-container'}>
        { hasPreviousButton && <GhostButton additionalClassNames={['previous-button']} content={previousButtonContent} onClick={goPrevious} /> }
      </span>
      { showPreviousEllipsis && <span className={'ellipsis-container'}><img className={'ellipsis'} src={ELLIPSIS} alt={'Previous'} /></span> }
      { paginationButtons }
      { showNextEllipsis && <span className={'ellipsis-container'}><img className={'ellipsis'} src={ELLIPSIS} alt={'Next'} /></span> }
      <span className={'right-button-container'}>
        { hasNextButton &&  <GhostButton additionalClassNames={['next-button']} content={nextButtonContent} onClick={goNext} /> }
      </span>
    </aside>
  )
}

export default PaginationBar;