import { postData } from './Api';

import { Recipient, Interaction, InteractionSync } from '../components/people/PeopleConstants';

const PostInteractionUrls = {
  SaveOrUpdateInteractionUrl: '/api/interaction/saveOrUpdate',
  SaveInteractionsUrl: '/api/interactions/save',
  SyncInteractionsUrl: '/api/interactions/sync',
};

const {
  SyncInteractionsUrl,
  SaveInteractionsUrl,
  SaveOrUpdateInteractionUrl,
} = PostInteractionUrls;

interface SyncInteractionsRequest {
  interactions: InteractionSync[],
  businessId: string,
};

interface SyncInteractionsResponse {
  success: boolean,
  syncedInteractions: InteractionSync[]
};

interface SaveInteractionsRequest {
  interactions: Interaction[],
  businessId: string,
};

interface SaveInteractionsResponse {
  success: boolean,
  savedInteractions: string[]
};

interface SaveUpdateInteractionsRequest {
  interaction: Interaction
};

interface SaveUpdateInteractionResponse {
  success: boolean,
  recipient: Recipient
};

/**
 * Syncs an interaction. Returns a promise with the list of recipients that match the interactions.
 */
export async function postSyncInteractions(interactions: InteractionSync[], businessId: string): Promise<SyncInteractionsResponse> {
  const { success, syncedInteractions } = await postData<SyncInteractionsRequest, SyncInteractionsResponse>(
    SyncInteractionsUrl, { interactions, businessId }
  );
  return { success, syncedInteractions };
};

/**
 * Saves a list of interactions. Returns a promise with the list of interactionIds that got updated.
 */
export async function postSaveInteractions(interactions: Interaction[], businessId: string): Promise<SaveInteractionsResponse> {
  const { success, savedInteractions } = await postData<SaveInteractionsRequest, SaveInteractionsResponse>(
    SaveInteractionsUrl, { interactions, businessId }
  );
  return { success, savedInteractions };
};

/**
 * Saves or updates an interaction. Returns a promise with the recipient enriched with the latest interaction data.
 */
export async function postSaveOrUpdateInteraction(interaction: Interaction): Promise<SaveUpdateInteractionResponse> {
  const { success, recipient } = await postData<SaveUpdateInteractionsRequest, SaveUpdateInteractionResponse>(
    SaveOrUpdateInteractionUrl, { interaction }
  );
  return { success, recipient };
};