import './FineTuneModal.scss';

import React, { useState } from 'react';

import { RecoverClientMessage } from '../RecoverClientsConstants';
import useAuth from '../../../../context/AuthContext';

import Modal from '../../../common/modals/Modal';
import TextAreaInput from '../../../common/inputs/TextAreaInput';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import { postFineTuneRecoverClientMessages } from '../../../../apis/RecoverClientsApi';

interface FineTuneModalProps {
  previews: RecoverClientMessage[],
  close: () => void,
  confirm: (fineTunedPreviews: RecoverClientMessage[]) => void,
};

function FineTuneModal({ previews, close, confirm } : Readonly<FineTuneModalProps>) {
  const { selectedBusinessId } = useAuth();
  const [fineTunePrompt, setFineTunePrompt] = useState('');
  const [loading, setLoading] = useState(false);

  const confirmTuning = async () => {
    if (selectedBusinessId) {
      setLoading(true);
      const fineTunedResponse = await postFineTuneRecoverClientMessages(fineTunePrompt, previews, selectedBusinessId);
      confirm(fineTunedResponse.recoverClientMessages);
      console.log(fineTunedResponse);
      setLoading(false);
    }
  }

  return (
    <Modal additionalClassNames={['fine-tune-modal']}>
      <section className={'fine-tune-modal-content'} >
        <h4 className={'title'}>{'Make adjustments to your message'}</h4>
        <h5 className={'subtitle'}>{'Fine tune your message by changing or replacing details in the message.'}</h5>
        <TextAreaInput text={fineTunePrompt} setText={setFineTunePrompt} placeholder={'Start typing your desired changes here...'} />
        <section className={'buttons'}>
          <OutlineButton content={'Cancel'} onClick={close} />
          <PrimaryButton content={'Confirm'} onClick={confirmTuning} loading={loading} />
        </section>
      </section>
    </Modal>
  )
};

export default FineTuneModal;