import './CaseStudyCard.scss';

import { ReactElement } from 'react';
import { useNavigate } from 'react-router';

import PrimaryButton from '../common/buttons/PrimaryButton';
import { CaseStudyType } from './CaseStudiesConstants';

interface CaseStudyCardProps {
  title: string,
  previewUrl: string,
  navigationUrl: string,
  descriptions: string[],
  type: CaseStudyType,
}

function CaseStudyCard({ title, navigationUrl, descriptions, type } : Readonly<CaseStudyCardProps>) : ReactElement<CaseStudyCardProps> {
  const navigate = useNavigate();
  const viewMore = () => navigate(navigationUrl);
  const descriptionContent = descriptions.map((description) => <p className={'description-line'} key={description}>{description}</p>);
  return (
    <section className={'case-study-card'}>
      <span className={'case-study-label'}>{type}</span>
      <section className={'card-content'}>
        <header className={'case-study-card-header'}>
          <h4 className={'title'}>{title}</h4>
        </header>
        <section className={'card-description'}>
          {descriptionContent}
        </section>
      </section>
      <PrimaryButton additionalClassNames={['learn-more-button']} onClick={viewMore} content={'Learn more'} />
    </section>
  );
}

export default CaseStudyCard;