import './CaseStudiesPage.scss';

import { ReactElement } from 'react';

import CaseStudyCard from './CaseStudyCard';

import { CaseStudyType } from './CaseStudiesConstants';

interface CaseStudiesPageProps {
  type: CaseStudyType | null;
}

const caseStudies = [
  {
    key: 1,
    title: 'Send handwritten letters to people that have just moved into the area',
    descriptions: [
      'Choose your target suburbs and we will find addresses recently purchased or rented',
      'We design, write and send on your behalf real handwritten letters that make you stand out',
    ],
    previewUrl: 'https://www.youtube.com/embed/ZtYaqKAc5mA?si=ifgER9mx0h8w_7iO',
    navigationUrl: '/find-clients/about',
    type: CaseStudyType.FindNewClients,
  },
  {
    key: 2,
    title: 'Send an SMS or email campaign to your past clients',
    descriptions: [
      'We automatically detect and find clients that may have churned or are close to churning',
      'We re-engage these clients using SMS and email to bring them back to your business',
    ],
    previewUrl: 'https://www.youtube.com/embed/-KjeI0Gdxhg?si=pZA89YM--TU1Meeu',
    navigationUrl: '/recover-clients/about',
    type: CaseStudyType.RecoverLostClients,
  }
];


function CaseStudiesPage({ type } : CaseStudiesPageProps): ReactElement<CaseStudiesPageProps> {
  const caseStudyCards = caseStudies
    .filter(caseStudy => !type || caseStudy.type === type)
    .map(({ key, title, previewUrl, navigationUrl, descriptions, type }) => 
      <CaseStudyCard key={key} title={title} previewUrl={previewUrl} navigationUrl={navigationUrl} descriptions={descriptions} type={type} />);
  return (
    <article className={'case-studies-page'}>
      <section className={'page'}>
        <header className={'case-studies-page-header'}>
          <h2 className={'title'}>{'Browse our services'}</h2>
          <h3 className={'subtitle'}>{'Click to learn more about each of our services'}</h3>
        </header>
        <section className={'page-content'}>
          {caseStudyCards}
        </section>
      </section>
    </article>
  );
}

export default CaseStudiesPage;