import { getData, postData } from './Api';
import { RecoverClientMessage } from '../components/case-studies/recover-clients/RecoverClientsConstants';

const PostCaseStudyUrls = {
  RecoverClientMessagesForBusinessUrl: '/api/recover-client-message/messages',
  UpdateRecoverClientMessagesUrl: '/api/recover-client-message/saveOrUpdateMany',
  DeleteRecoverClientMessagesUrl: '/api/recover-client-message/deleteMany',
  SendRecoverClientMessagesUrl: '/api/recover-client-message/sendMany',
  FineTuneRecoverClientMessagesUrl: '/api/recover-client-message/fineTune',
  RecoverClientSetupUrl: '/api/recover-client-message/setup',
};

const {
  RecoverClientMessagesForBusinessUrl,
  UpdateRecoverClientMessagesUrl,
  DeleteRecoverClientMessagesUrl,
  SendRecoverClientMessagesUrl,
  FineTuneRecoverClientMessagesUrl,
  RecoverClientSetupUrl
} = PostCaseStudyUrls;

interface RecoverClientMessagesRequest {
  recoverClientMessages: RecoverClientMessage[]
  businessId: string,
};

interface RecoverClientMessagesResponse {
  recoverClientMessages: RecoverClientMessage[]
};

interface RecoverClientMessagesFineTuneRequest {
  fineTunePrompt: string,
  recoverClientMessages: RecoverClientMessage[],
  businessId: string
};

// Recover Lost Client - Client Type Setup Request
interface RecoverClientMessagesSetupRequest {
  businessId: string,
  requestDetails: string,
};

interface RecoverClientMessagesDeleteManyRequest {
  recoverClientMessageIds: string[],
}

interface RecoverClientMessagesDeleteManyResponse {
  success: boolean,
}

interface RecoverClientMessagesSendManyRequest {
  recoverClientMessageIds: string[],
  businessId: string
}

interface RecoverClientMessagesSendManyResponse {
  success: boolean,
}

/**
 * Fetches all recover client messages for a particular business id.
 */
export async function getRecoverClientMessagesForBusiness(businessId: string): Promise<RecoverClientMessagesResponse> {
  console.log({ businessId });
  return getData<RecoverClientMessagesResponse>(`${RecoverClientMessagesForBusinessUrl}/${businessId}`);
}

/**
 * Fetches all recover client messages for a particular business id.
 */
export async function postRecoverClientMessagesUpdate(recoverClientMessages: RecoverClientMessage[], businessId: string): Promise<RecoverClientMessagesResponse> {
  return postData<RecoverClientMessagesRequest, RecoverClientMessagesResponse>(
    UpdateRecoverClientMessagesUrl, { recoverClientMessages, businessId }
  );
}

/**
 * Fine tunes the selected recover client messages.
 */
export async function postFineTuneRecoverClientMessages(fineTunePrompt: string, recoverClientMessages: RecoverClientMessage[], businessId: string): Promise<RecoverClientMessagesResponse> {
  return postData<RecoverClientMessagesFineTuneRequest, RecoverClientMessagesResponse>(
    FineTuneRecoverClientMessagesUrl, { fineTunePrompt, recoverClientMessages, businessId }
  );
}

/**
 * Fine tunes the selected recover client messages.
 */
export async function postRecoverClientsSetup(businessId: string, requestDetails: string): Promise<boolean> {
  return postData<RecoverClientMessagesSetupRequest, boolean>(
    RecoverClientSetupUrl, { businessId, requestDetails }
  );
}

/**
 * Sets the deletion date for the selected recover client messages.
 */
export async function postRecoverClientsDeleteMany(recoverClientMessageIds: string[]): Promise<RecoverClientMessagesDeleteManyResponse> {
  return postData<RecoverClientMessagesDeleteManyRequest, RecoverClientMessagesDeleteManyResponse>(
    DeleteRecoverClientMessagesUrl, { recoverClientMessageIds }
  );
}

/**
 * Sets the deletion date for the selected recover client messages.
 */
export async function postRecoverClientsSendMany(recoverClientMessageIds: string[], businessId: string): Promise<RecoverClientMessagesSendManyResponse> {
  return postData<RecoverClientMessagesSendManyRequest, RecoverClientMessagesSendManyResponse>(
    SendRecoverClientMessagesUrl, { recoverClientMessageIds, businessId }
  );
}