import './AboutPage.scss';

import { ReactElement } from 'react';

import PrimaryButton from '../../common/buttons/PrimaryButton';


interface AboutPageProps {
  title: string,
  subtitle: string,
  previewUrl: string,
  onStartClick: () => void,
}

function AboutPage({ title, subtitle, previewUrl, onStartClick } : AboutPageProps): ReactElement<AboutPageProps> {
  return (
    <article className={'about-page'}>
      <section className={'page'}>
        <header className={'about-page-header'}>
          <h2 className={'title'}>{title}</h2>
          <h3 className={'subtitle'}>{subtitle}</h3>
        </header>
        <section className={'page-content'}>
          <section className={'preview-container'}>
            <iframe
              className={'preview-iframe'}
              src={previewUrl}
              title={title}
              allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
              referrerPolicy={'strict-origin-when-cross-origin'}
              allowFullScreen
            />
          </section>
          <section className={'call-to-action'}>
            <span className={'call-to-action-label'}>{'Ready to get started?'}</span>
            <PrimaryButton content={'Start'} onClick={onStartClick} additionalClassNames={['call-to-action-setup-button']} />
          </section>
        </section>
      </section>
    </article>
  );
}

export default AboutPage;