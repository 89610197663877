import './AddRecoverClientsFilterModal.scss';

import React, { useState } from 'react';

import Modal from '../../../common/modals/Modal';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import PrimaryPlusButton from '../../../common/buttons/composite/PrimaryPlusButton';
import { RecoverClientsFilterAttributeKey, RecoverClientsFilterType } from '../../../common/multi-select-rows/filters/FilterConstants';
import { ConditionKey } from '../../../common/multi-select-rows/MultiSelectRowConstants';
import RecoverClientsFilterRow from '../../../common/multi-select-rows/filters/RecoverClientsFilterRow';

const EMPTY_FILTER = {
  attribute: null,
  condition: null,
  input: '',
};

interface AddRecoverClientsFilterModalProps {
  parentFilters: RecoverClientsFilterType[],
  close: () => void,
  confirm: (filters: RecoverClientsFilterType[]) => void,
  resetFilters: () => void,
}

function AddRecoverClientsFilterModal({ parentFilters, close, confirm, resetFilters } : Readonly<AddRecoverClientsFilterModalProps>) {
  const [filters, setFilters] = useState<RecoverClientsFilterType[]>(parentFilters);
  const applyFilters = () => {
    console.log(filters)
    confirm(filters);
    close();
  };
  const reset = () => {
    resetFilters();
    setFilters([]);
  }
  const addFilter = () => {
    const newFilters = [...filters, { ...EMPTY_FILTER }];
    setFilters(newFilters);
  }
  const setFilter = (index: number, fieldName: string, value: string | number) => {
    const newFilters = [...filters];
    newFilters[index] = { ...newFilters[index], [fieldName]: value };
    setFilters(newFilters);
  }

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  }

  const filterList = filters.map(({ attribute, condition, input }, index) => {
    const setAttribute = (attribute: RecoverClientsFilterAttributeKey) => setFilter(index, 'attribute', attribute);
    const setCondition = (condition: ConditionKey) => setFilter(index, 'condition', condition);
    const setInput = (input: string | number) => setFilter(index, 'input', input);
    return (
      <RecoverClientsFilterRow
        key={`filter-${index}`}
        attribute={attribute}
        setAttribute={setAttribute}
        condition={condition}
        setCondition={setCondition}
        input={input}
        setInput={setInput}
        deleteRow={() => deleteFilter(index)}
      />
    );
  });
  return (
    <Modal additionalClassNames={['add-filter-modal']}>
      <header className={'add-filter-modal-header'}>
        <h4 className={'add-filter-title'}>
          {'Add filters'}
        </h4>
        <h5 className={'add-filter-subtitle'}>
          {'You can add multiple filters'}
        </h5>
      </header>
      <section className={'add-filter-content'}>
        <section className={'filters'}>
          {filterList}
        </section>
        <PrimaryPlusButton content={'New filter'} onClick={addFilter} />
      </section>
      <section className={'add-filter-buttons'}>
        <OutlineButton content={'Reset'} onClick={reset} />
        <PrimaryButton content={'Apply'} onClick={applyFilters} />
      </section>
    </Modal>
  );
}

export default AddRecoverClientsFilterModal;