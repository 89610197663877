import parser from 'any-date-parser';

import { RecipientFilterType, RECIPIENTS_FILTER_ATTRIBUTES_MAP, FILTER_CONDITIONS_MAP, AddressFilterType, ADDRESS_FILTER_ATTRIBUTES_MAP, RECOVER_CLIENTS_FILTER_ATTRIBUTES_MAP, RecoverClientsFilterType } from './FilterConstants';
import { VALUE_TYPES } from '../../../../Constants';
import { Address, CountryCode } from '../../../case-studies/find-clients/HandwrittenLettersConstants';
import { Recipient } from '../../../people/PeopleConstants';
import { RecoverClientMessage } from '../../../case-studies/recover-clients/RecoverClientsConstants';

export interface RecipientRow extends Recipient {
  _id: string,
  alreadySelected: boolean,
}

const { STRING, NUMBER, DATE } = VALUE_TYPES;

export function filterRecipients(recipients: RecipientRow[] | null, filters: RecipientFilterType[]) {
  if (recipients) {
    return recipients.filter((recipient: RecipientRow | Recipient) => {
      // Only compute filters with conditions
      const populatedFilters = filters.filter((filter: RecipientFilterType) => filter.condition);
  
      for (const filter of populatedFilters) {
        const { attribute, condition, input } = filter;
        if (!attribute || !condition) {
          return false;
        }

        const recordVal = Object.hasOwn(recipient, attribute)
          ? recipient[attribute]
          : null;
  
        const attributeType = RECIPIENTS_FILTER_ATTRIBUTES_MAP[attribute].type;
        const conditionObject = FILTER_CONDITIONS_MAP[attributeType][condition];
  
        if (!conditionObject) return false;
  
        let filterResult = false;
  
        const comparedValueTypes = [STRING, NUMBER, DATE];
        if (comparedValueTypes.includes(attributeType)) {
          filterResult = conditionObject.filterFunction(recordVal, input);
        }
  
        if (!filterResult) {
          return false;
        }
      }
  
      return true;
    })
  }
  return [];
}

function validateAndFormatNumeric(value: any, valueType: VALUE_TYPES, countryCode: CountryCode | null) {
  if (typeof value === 'string' && valueType === DATE) {
    const date = countryCode ? parser.fromString(value, countryCode) : parser.fromString(value);
    return typeof date.getTime === 'function' ? date.getTime() : null;
  }
  if (typeof value === 'string' && valueType === NUMBER) {
    return value.replace(/\D/g, '');
  }
  return value;
}

export function filterAddresses(addresses: Address[] | null, filters: AddressFilterType[]) {
  if (addresses) {
    return addresses.filter((address: Address) => {
      // Only compute filters with conditions
      const { countryCode = null } = address;
      const populatedFilters = filters.filter((filter: AddressFilterType) => filter.condition);
  
      for (const filter of populatedFilters) {
        const { attribute, condition, input } = filter;
        if (!attribute || !condition) {
          return false;
        }

        const attributeType = ADDRESS_FILTER_ATTRIBUTES_MAP[attribute].type;
        const conditionObject = FILTER_CONDITIONS_MAP[attributeType][condition];

        const recordVal = Object.hasOwn(address, attribute)
          ? validateAndFormatNumeric(address[attribute], attributeType, countryCode)
          : null;
  
        if (!conditionObject) return false;
  
        let filterResult = false;
  
        const comparedValueTypes = [STRING, NUMBER, DATE];
        if (comparedValueTypes.includes(attributeType)) {
          filterResult = conditionObject.filterFunction(recordVal, input);
        }
  
        if (!filterResult) {
          return false;
        }
      }
  
      return true;
    })
  }
  return [];
}

export function searchRecipients(recipients: RecipientRow[], searchText: string) {
  if (searchText.length === 0) {
    return recipients;
  }
  if (recipients) {
    return recipients.filter(({ firstName, lastName }) => {
      const name = `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`;
      return name.toLowerCase().includes(searchText.toLowerCase());
    })
  }
  return [];
};

export function searchAddresses(addresses: Address[], searchText: string) {
  if (searchText.length === 0) {
    return addresses;
  }
  if (addresses) {
    return addresses.filter(({ address, searchValue }) => {
      const addressMatch = address.toLowerCase().includes(searchText.toLowerCase());
      const searchValueMatch = searchValue.toLowerCase().includes(searchText.toLowerCase())
      return addressMatch || searchValueMatch;
    })
  }
  return [];
};

export function filterRecoverClientMessages(recoveryMessages: RecoverClientMessage[] | null, filters: RecoverClientsFilterType[]) {
  if (recoveryMessages) {
    return recoveryMessages.filter((recoveryMessage: RecoverClientMessage) => {
      // Only compute filters with conditions
      const populatedFilters = filters.filter((filter: RecoverClientsFilterType) => filter.condition);
  
      for (const filter of populatedFilters) {
        const { attribute, condition, input } = filter;
        if (!attribute || !condition) {
          return false;
        }

        const attributeType = RECOVER_CLIENTS_FILTER_ATTRIBUTES_MAP[attribute].type;
        const conditionObject = FILTER_CONDITIONS_MAP[attributeType][condition];

        const recordVal = Object.hasOwn(recoveryMessage, attribute)
          ? validateAndFormatNumeric(recoveryMessage[attribute], attributeType, null)
          : null;
  
        if (!conditionObject) return false;
  
        let filterResult = false;
  
        const comparedValueTypes = [STRING, NUMBER, DATE];
        if (comparedValueTypes.includes(attributeType)) {
          filterResult = conditionObject.filterFunction(recordVal, input);
        }
  
        if (!filterResult) {
          return false;
        }
      }
  
      return true;
    })
  }
  return [];
}