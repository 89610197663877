import { useEvent, useFlatfile, Workbook } from "@flatfile/react";

import useAuth from '../../../../context/AuthContext';

import { InteractionFlatFileComponentProps, interactionWorkbook } from "../../../../utils/flatfile/FlatFileConstants";
import { interactionSubmitListener, interactionSyncListener } from "../../../../utils/flatfile/FlatFileHelpers";
import OutlineButton from "../../../common/buttons/OutlineButton";

function InteractionFlatFileButton({ defaultDisplayText, onSubmit }: InteractionFlatFileComponentProps) {
  const { openPortal } = useFlatfile();
  const { selectedBusinessId } = useAuth();

  useEvent('job:ready',
    { job: 'workbook:syncInteractions' },
    async (event) => {
      if (selectedBusinessId) {
        await interactionSyncListener(event, selectedBusinessId);
      }
    }
  );

  useEvent('job:ready',
    { job: 'workbook:submitInteractions' },
    async (event) => {
      if (selectedBusinessId) {
        await interactionSubmitListener(event, selectedBusinessId);
        await onSubmit();
      }
    }
  );

  return (
    <>
      <OutlineButton content={defaultDisplayText} onClick={openPortal} />
      <Workbook config={interactionWorkbook} />
    </>
  )
};

export default InteractionFlatFileButton;