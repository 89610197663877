import { getData, postData } from './Api';

import { RecipientRow } from '../components/common/multi-select-rows/filters/FilterHelpers';
import { Recipient, RecipientId } from '../components/people/PeopleConstants';

const PostPeopleUrls = {
  GetRecipientsUrl: '/api/recipients',
  GetFilteredRecipientsUrl: '/api/recipients/filtered',
  SaveOrUpdateRecipientsUrl: '/api/recipients/saveOrUpdate',
  DeleteRecipientUrl: '/api/recipients/delete',
};

const {
  GetRecipientsUrl,
  GetFilteredRecipientsUrl,
  SaveOrUpdateRecipientsUrl,
  DeleteRecipientUrl,
} = PostPeopleUrls;

interface FindRecipientsWithFiltersRequest {
  page: number,
  searchText?: string
};

interface FindRecipientsWithFiltersResponse {
  success: boolean,
  recipients: Recipient[]
};

interface SaveOrUpdateRecipientsRequest {
  recipients: Recipient[],
  businessId: string
};

interface SaveOrUpdateRecipientsResponse {
  success: boolean,
  recipientIds: RecipientId[]
};

interface DeleteRecipientResponse {
  success: boolean
};

/**
 * Retrieves the user from the server.
 */
export async function getRecipients(): Promise<{ success: boolean, recipients: RecipientRow[]}> {
  return getData(GetRecipientsUrl);
}

/**
 * Retrieves the user from the server.
 */
export async function getRecipientsWithFilters(page: number, searchText: string = ''): Promise<FindRecipientsWithFiltersResponse> {
  const { success, recipients } = await postData<FindRecipientsWithFiltersRequest, FindRecipientsWithFiltersResponse>(
    GetFilteredRecipientsUrl, { page, searchText }
  );
  return { success, recipients };
}

/**
 * Saves or updates a recipient. Returns a promise with the list of the recipientIds successfully saved or updated.
 */
export async function postSaveOrUpdateRecipients(recipients: Recipient[], businessId: string): Promise<SaveOrUpdateRecipientsResponse> {
  const { success, recipientIds } = await postData<SaveOrUpdateRecipientsRequest, SaveOrUpdateRecipientsResponse>(
    SaveOrUpdateRecipientsUrl, { recipients, businessId }
  );
  return { success, recipientIds };
};

/**
 * Deletes a recipient. Returns a promise with a boolean on whether the deletion was successful or not.
 */
export async function postDeleteRecipient(recipientId: string): Promise<DeleteRecipientResponse> {
  const { success } = await postData<{ recipientId: string }, { success: boolean }>(
    DeleteRecipientUrl, { recipientId }
  );
  return { success };
};
