import './RecoverClientsMessagePage.scss';

import React, { ReactElement, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';

import { RecoverClientMessage } from './RecoverClientsConstants';
import { postRecoverClientMessagesUpdate } from '../../../apis/RecoverClientsApi';
import useAuth from '../../../context/AuthContext';

import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import RecoverClientsPreview from './previews/RecoverClientsPreview';

const mainPageUrl = '/recover-clients/manage';
const previewSubtitle = 'View and edit your message before approving it to be sent out.';

function RecoverClientsMessagePage(): ReactElement {
  const { selectedBusinessId } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state);

  const recoverClientMessage = state && state.recoverClientMessage !== undefined ? state.recoverClientMessage : null;
  const back = () => {
    navigate(mainPageUrl, { state: { recoverClientMessages: previewMessages }});
  }
  const [previewMessages, setPreviewMessages] = useState<RecoverClientMessage[]>(recoverClientMessage);
  if (!recoverClientMessage || recoverClientMessage.length === 0) {
    return <Navigate to={mainPageUrl} />;
  }

  const saveMessages = (updatedMessage: string, messageIndex: number) => {
    const newPreviews = previewMessages;
    newPreviews[messageIndex].message = updatedMessage;
    setPreviewMessages(newPreviews);
  }

  const approveMessage = async () => {
    if (selectedBusinessId) {
      const savedMessage = await postRecoverClientMessagesUpdate(previewMessages, selectedBusinessId);
      console.log(savedMessage);
      back();
    }
    else {
      back();
    }
  };

  const totalMessages = previewMessages.length;
  return (
    <article className={'recover-clients-preview-page'}>
      <section className={'recover-clients-preview-page-content'}>
        <header className={'recover-clients-preview-page-header'}>
          <h2 className={'title'}>{'Preview your messages'}</h2>
          <section className={'subtitle-container'}>
            <section className={'subtitle-and-search'}>
              <h3 className={'subtitle'}>
                {previewSubtitle}
              </h3>
            </section>
          </section>
        </header>
        <RecoverClientsPreview
          previews={previewMessages}
          setPreview={saveMessages}
          setAllPreviews={setPreviewMessages}
          totalMessages={totalMessages}
        />
      </section>
      <footer className={'footer'}>
        <section className={'footer-left'}>
          <OutlineButton content={'Back'} onClick={back} />
          <PrimaryButton content={'Approve message'} onClick={approveMessage} />
        </section>
        {/* <section className={'footer-right'}>{stepText}</section> */}
      </footer>
    </article>
  );
};

export default RecoverClientsMessagePage;