export async function getData<T>(url: string): Promise<T> {
  console.log(url);
  const response = await fetch(url, { method: 'GET', mode: 'cors', cache: 'default' })
    .then(response => response.json())
    .catch(err => console.error(err));
  return response;
};

export async function postData<T, R>(url: string, data: T): Promise<R> {
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  .then(response => response.json())
  .catch(err => console.error(err));
};
