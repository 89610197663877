import { useEvent, useFlatfile, usePlugin, Workbook } from "@flatfile/react";

import useAuth from '../../../context/AuthContext';

import UPLOAD_ICON from '../assets/upload-icon.svg';
import { AddressFlatFileComponentProps, addressWorkbook } from "../../../utils/flatfile/FlatFileConstants";
import { addressSubmitListener, addressRecordFormattingListener } from "../../../utils/flatfile/FlatFileHelpers";
import { recordHook } from "@flatfile/plugin-record-hook";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import { getAddressesForBusiness } from "../../../apis/CaseStudyApi";

function AddressFlatFileButton({ setAddresses } : AddressFlatFileComponentProps) {
  const { openPortal } = useFlatfile();
  const { selectedBusinessId } = useAuth();

  const uploadButtonContent = (
    <section className={'button-content'}>
      <img className={'upload-icon'} src={UPLOAD_ICON} alt={'Upload icon'} />
      <span className={'button-text'}>{'Manually upload addresses'}</span>
    </section>
  );

  usePlugin(
    recordHook(
      'addresses',
      addressRecordFormattingListener
    ),
    ['status', 'shouldSend']
  )

  // Event hook for submitting recipients
  useEvent('job:ready',
    { job: 'workbook:submitAddresses' },
    async (event) => {
      if (selectedBusinessId) {
        await addressSubmitListener(event, selectedBusinessId);
        getAddressesForBusiness(selectedBusinessId)
          .then(res => setAddresses(res.addresses))
          .catch(err => console.log(err));
      }
    }
  );

  return (
    <>
      <PrimaryButton content={uploadButtonContent} onClick={openPortal} />
      <Workbook config={addressWorkbook} />
    </>
  )
};

export default AddressFlatFileButton;