import './TextAreaInput.scss';

import React, { FormEvent } from 'react';

export interface TextAreaInputProps {
  text: string,
  setText: ((value: string) => void),
  placeholder: string,
  id?: string,
  disabled?: boolean,
  additionalClassNames?: string[],
  rows?: number,
}

function TextAreaInput({ text, setText, placeholder, id = '', disabled = false, additionalClassNames = [], rows = 7 }: Readonly<TextAreaInputProps>) {
  const onChange = (event: FormEvent<HTMLTextAreaElement>) => setText((event.target as HTMLTextAreaElement).value);
  return <textarea
    id={id}
    className={`text-area-input ${additionalClassNames.join(' ')}`}
    placeholder={placeholder}
    value={text}
    onChange={onChange}
    disabled={disabled}
    rows={rows}
  />;
}

export default TextAreaInput;