import './FindClientsManagePage.scss';

import { ReactElement, useState, useEffect } from 'react';

import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import GhostButton from '../../common/buttons/GhostButton';
import { Address, LetterStatus } from './HandwrittenLettersConstants';

import SHOW_ICON from '../assets/show-completed-icon.svg';
import HIDE_ICON from '../assets/hide-completed-icon.svg';
import HandwrittenLettersTable from './tables/HandwrittenLettersTable';
import { getAddressesForBusiness, sendLettersToAddresses } from '../../../apis/CaseStudyApi';
import ConfirmSendLettersModal from './modals/ConfirmSendLettersModal';
import AddressFilter from '../common/AddressFilter';
import { AddressFilterType } from '../../common/multi-select-rows/filters/FilterConstants';
import SearchInput from '../../common/inputs/SearchInput';
import { filterAddresses, searchAddresses } from '../../common/multi-select-rows/filters/FilterHelpers';
import AddressFlatfileImport from '../../../utils/flatfile/AddressFlatfileImport';
import useAuth from '../../../context/AuthContext';

function FindClientsSetupPage(): ReactElement {
  const { selectedBusinessId, user } = useAuth();
  const businesses = user?.businesses || [];
  const business = businesses.find(({ _id }) => _id === selectedBusinessId) || undefined;
  const offerUrl = business?.offerUrl;
  const letterUrl = business?.letterUrl;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showCompleted, setShowCompleted] = useState(true);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [filters, setFilters] = useState<AddressFilterType[]>([]);
  const [text, setText] = useState<string>('');
  const [cursor, setCursor] = useState(0);
  const showCompletedToggle = (
    <section className={'show-completed-toggle'}>
      <img className={'show-completed-icon'} src={showCompleted ? SHOW_ICON : HIDE_ICON} alt={'Show completed icon'} />
      <span className={'button-text'}>{showCompleted ? 'Showing completed' : 'Hiding completed'}</span>
    </section>
  );

  const searchForAddresses = (searchText: string) => {
    setText(searchText);
    setCursor(0);
  };

  const toggleCompleted = () => setShowCompleted(!showCompleted);

  const setFilteredAddressesAndResetPage = () => {
    setCursor(0);
  }

  const setNewAddressData = (newAddressData: Address[]) => {
    setAddresses(newAddressData);
    setFilteredAddressesAndResetPage();
  };

  useEffect(() => {
    setNewAddressData([]);
  }, [selectedBusinessId]);

  useEffect(() => {
    if (selectedBusinessId) {
      getAddressesForBusiness(selectedBusinessId).then(res => setNewAddressData(res.addresses));
    }
  }, [selectedBusinessId]);
  
  const closeConfirmModal = () => setConfirmModalOpen(false);
  const openConfirmModal = () => setConfirmModalOpen(true);

  const filteredStatusAddresses = addresses.filter(({ status }) => showCompleted || status !== LetterStatus.Complete);
  const addressesToDisplay = filterAddresses(filteredStatusAddresses, filters);
  const searchedAddresses = searchAddresses(addressesToDisplay, text);
  const showSendAllButton = filteredStatusAddresses.filter(
    ({ shouldSend, status }) => !shouldSend && status !== LetterStatus.Complete && status !== LetterStatus.Requested).length > 0;

  const sendLetters = async () => {
    const addressIds = filteredStatusAddresses
      .filter(({ shouldSend }) => !shouldSend)
      .map(({ _id }) => _id);
    if (addressIds.length === 0) {
      closeConfirmModal();
      return;
    }
    const newAddresses = addresses.map((address) => {
      if (addressIds.includes(address._id)) {
        return {
          ...address,
          shouldSend: true,
        };
      }
      return address;
    });
    setAddresses(newAddresses);
    await sendLettersToAddresses(addressIds);
    closeConfirmModal();
  }
  const viewLetter = (): void => {
    if (letterUrl) {
      window.open(letterUrl, "_blank");
    }
  };
  const viewOffer= (): void => {
    if (offerUrl) {
      window.open(offerUrl, "_blank");
    }
  };
  return (
    <article className={'find-clients-manage-page'}>
      { confirmModalOpen && <ConfirmSendLettersModal cancel={closeConfirmModal} confirm={sendLetters} /> }
      <section className={'page'}>
        <header className={'find-clients-manage-page-header'}>
          <h2 className={'title'}>{'Manage your outreach'}</h2>
          <h3 className={'subtitle'}>{'View addresses, your messages and confirm send as you see fit'}</h3>
          <section className={'search-bar'}>
            <SearchInput
              placeholder={'Type to search'}
              text={text}
              setText={searchForAddresses}
            />
          </section>
          <section className={'filter-bar'}>
            <AddressFilter
              filteredAddresses={searchedAddresses}
              addresses={addresses}
              filters={filters}
              setFilters={setFilters}
              searchText={text}
            />
          </section>
          <section className={'find-clients-buttons'}>
            <AddressFlatfileImport setAddresses={setNewAddressData} />
            { letterUrl && <OutlineButton additionalClassNames={['view-letter-button']} content={'View your letter'} onClick={viewLetter} /> }
            { offerUrl && <OutlineButton additionalClassNames={['view-offer-button']} content={'View your offer'} onClick={viewOffer} /> }
            { showSendAllButton && <PrimaryButton additionalClassNames={['send-all-button']} content={'Send all'} onClick={openConfirmModal} /> }
            <GhostButton additionalClassNames={['show-complete-button']} content={showCompletedToggle} onClick={toggleCompleted} />
          </section>
        </header>
        <section className={'page-content'}>
          <HandwrittenLettersTable
            addresses={searchedAddresses}
            setAddresses={setAddresses}
            cursor={cursor}
            setCursor={setCursor}
          />
        </section>
      </section>
    </article>
  );
}

export default FindClientsSetupPage;