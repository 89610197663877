import './AddressFilterRow.scss';

import { VALUE_TYPES } from '../../../../Constants';
import DateInput from '../../inputs/DateInput';
import Dropdown from '../../dropdowns/Dropdown';
import MultiSelectRow from '../MultiSelectRow';
import PrimaryInput from '../../inputs/PrimaryInput';
import {
  AddressFilterAttributeKey,
  ADDRESS_FILTER_ATTRIBUTES,
  CONDITION_OPTIONS_MAPPING,
  ADDRESS_FILTER_ATTRIBUTES_MAP,
  FILTER_CONDITIONS_MAP
} from './FilterConstants';
import { ConditionKey } from '../MultiSelectRowConstants';

const { IsEmptyKey, IsNotEmptyKey } = ConditionKey;
const { DATE } = VALUE_TYPES;

const EMPTY_CONDITIONS = [IsEmptyKey, IsNotEmptyKey];

interface AddressFilterRowProps {
  attribute: AddressFilterAttributeKey | null,
  setAttribute: (attribute: AddressFilterAttributeKey) => void,
  condition: ConditionKey | null,
  setCondition: (condition: ConditionKey) => void,
  input: string | number,
  setInput: (input: number | string) => void,
  deleteRow: () => void,
}

function AddressFilterRow({ attribute, setAttribute, condition, setCondition, input, setInput, deleteRow } : Readonly<AddressFilterRowProps>) {
  const showConditions = attribute !== null;
  const showInput = condition !== null && !EMPTY_CONDITIONS.includes(condition);

  // Need to figure out a better way to do this.
  const attributeType = showConditions ? ADDRESS_FILTER_ATTRIBUTES_MAP[attribute].type : '';

  const dateAttribute = attributeType === DATE;
  const dateInput = dateAttribute && input
    ? new Date(input)
    : new Date();

  let inputField = null;
  if (showConditions && showInput) {
    const conditionObj = FILTER_CONDITIONS_MAP[attributeType][condition];
    if (conditionObj && conditionObj.valueType === DATE) {
      inputField = <DateInput selectedDate={dateInput} setDate={setInput} />
    }
    else {
      inputField = <PrimaryInput placeholder={'Add value'} text={`${input}`} setText={setInput} />;
    }
  }
  
  return (
    <MultiSelectRow deleteRow={deleteRow}>
      <Dropdown defaultDisplayText={'Select attribute'} options={ADDRESS_FILTER_ATTRIBUTES} selected={attribute} select={setAttribute} additionalClassNames={['address-attributes-dropdown']} />
      { showConditions && attributeType && <Dropdown defaultDisplayText={'Select condition'} options={CONDITION_OPTIONS_MAPPING[attributeType]} selected={condition} select={setCondition} additionalClassNames={['attribute-conditions-dropdown']} /> }
      { showInput && inputField }
    </MultiSelectRow>
  );
}

export default AddressFilterRow;