export enum LetterStatus {
  All = 'All',
  Available = 'Available',
  Requested = 'Requested',
  Complete = 'Complete',
};

export enum AddressType {
  Sale = 'Sale',
  Rent = 'Rent',
};

export enum CountryCode {
  US = 'US',
  AU = 'AU',
  NZ = 'NZ',
}

export interface Address {
  _id: string,
  address: string,
  searchValue: string,
  status: LetterStatus,
  propertyValue: string,
  addressType: AddressType,
  transactionDate: string,
  countryCode?: CountryCode,
  shouldSend: boolean,
};

export const AddressFilterOptions = [
  {
    displayText: 'All addresses',
    value: LetterStatus.All,
  },
  {
    displayText: 'Available',
    value: LetterStatus.Available,
  },
  {
    displayText: 'Requested',
    value: LetterStatus.Requested,
  },
  {
    displayText: 'Complete',
    value: LetterStatus.Complete,
  },
]