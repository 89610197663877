import './RecoverClientsManagePage.scss';

import { ReactElement, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import useAuth from '../../../context/AuthContext';
import { RecoverClientsFilterType } from '../../common/multi-select-rows/filters/FilterConstants';
import { FLATFILE_UPLOAD_TYPE, FlatFileTypeKey, MessageStatus, RecoverClientMessage } from './RecoverClientsConstants';
import { RecipientRow, filterRecoverClientMessages } from '../../common/multi-select-rows/filters/FilterHelpers';
import { getRecoverClientMessagesForBusiness, postRecoverClientsSendMany } from '../../../apis/RecoverClientsApi';

import Dropdown from '../../common/dropdowns/Dropdown';
import RecipientFlatfileImport from '../../../utils/flatfile/RecipientFlatfileImport';
import InteractionFlatfileImport from '../../../utils/flatfile/InteractionFlatfileImport';
import RecoverClientsTable from './tables/RecoverClientsTable';
import SubtleButton from '../../common/buttons/SubtleButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';

import RecoverClientsFilter from '../common/RecoverClientsFilter';
import ConfirmMessageSendModal from './modals/ConfirmMessageSendModal';
import RecoverClientsEmptyPage from './RecoverClientsEmptyPage';

const { Delivered, NotSent } = MessageStatus;

function RecoverClientsManagePage(): ReactElement {
  const navigate = useNavigate();
  const { selectedBusinessId } = useAuth();
  const { state } = useLocation();
  const [recipients, setRecipients] = useState<RecipientRow[]>([]);
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const openSendModal = () => setSendModalOpen(true);
  const closeSendModal = () => setSendModalOpen(false);

  console.log(state);
  const [recoverClientMessages, setRecoverClientMessages] = useState<RecoverClientMessage[]>(state ? state.recoverClientMessages : []);
  const [flatFileUploadType, setFlatFileUploadType] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<RecoverClientMessage[]>([]);

  const [filters, setFilters] = useState<RecoverClientsFilterType[]>([]);
  const [cursor, setCursor] = useState(0);

  const filteredRecoverClientMessages = filterRecoverClientMessages(recoverClientMessages, filters);
  const retrieveLatestMessages = async () => {
    if (selectedBusinessId) {
      const response = await getRecoverClientMessagesForBusiness(selectedBusinessId);
      setNewRecoveryMessageData(response.recoverClientMessages);
    }
  }

  const importButton = flatFileUploadType === FlatFileTypeKey.ContactsKey
  ? <RecipientFlatfileImport setRecipients={setRecipients} defaultDisplayText={'Upload file'} />
  : <InteractionFlatfileImport onSubmit={retrieveLatestMessages} defaultDisplayText={'Upload file'}  />;

  const resetPage = () => setCursor(0);

  const setNewRecoveryMessageData = (newRecoveryMessages: RecoverClientMessage[]) => {
    setRecoverClientMessages(newRecoveryMessages);
    resetPage();
  };

  useEffect(() => {
    if (selectedBusinessId) {
      getRecoverClientMessagesForBusiness(selectedBusinessId).then(res => setNewRecoveryMessageData(res.recoverClientMessages));
    }
  }, [selectedBusinessId]);

  const fineTune = () => {
    const messagesToFineTune = selectedRows.length === 0
      ? filteredRecoverClientMessages
      : selectedRows;
    const notSentMessagesToFineTune = messagesToFineTune.filter(({ messageStatus }) => messageStatus === NotSent);
    navigate('/recover-clients/manage/edit', { state: { recoverClientMessage: notSentMessagesToFineTune }});
  };

  const sendRecoverClientMessages = () => {
    if (selectedBusinessId) {
      const selectedMessages = selectedRows.length > 0 ? selectedRows : recoverClientMessages;
      const filteredMessages = selectedMessages.filter(selectedMessage => selectedMessage.messageStatus !== Delivered)
      const filteredMessageIds = filteredMessages.map(filteredMessage => filteredMessage._id);
      postRecoverClientsSendMany(filteredMessageIds, selectedBusinessId)
        .then(response => {
          console.log(response);
          const { success } = response;
          if (success) {
            const updatedRecoverClientMessages = recoverClientMessages.map((recoverClientMessage) =>
              filteredMessageIds.includes(recoverClientMessage._id)
                ? ({ ...recoverClientMessage, messageStatus: Delivered })
                : recoverClientMessage
            );
            setRecoverClientMessages(updatedRecoverClientMessages);
          }
        })
    }
    closeSendModal();
  }

  const hasNoUnsentMessages = filteredRecoverClientMessages.filter(({ messageStatus }) => messageStatus === NotSent).length === 0;
  const sendMessagesText = selectedRows.length > 0 ? 'Send selected' : 'Send all';
  if (recoverClientMessages.length === 0) {
    return <RecoverClientsEmptyPage flatFileUploadType={flatFileUploadType} importButton={importButton} setFlatFileUploadType={setFlatFileUploadType} />;
  }
  return (
    <article className={'recover-clients-manage-page'}>
      { sendModalOpen && <ConfirmMessageSendModal cancel={closeSendModal} confirm={sendRecoverClientMessages} /> }
      <section className={'page'}>
        <header className={'recover-clients-manage-page-header'}>
          <h2 className={'title'}>{'Manage your lost clients'}</h2>
          <h3 className={'subtitle'}>{'View, edit and send messages to the clients who haven’t visited you in a while.'}</h3>
          <section className={'recover-clients-button-dashboard'}>
            <section className={'fine-tune-message-buttons'}>
              <SubtleButton additionalClassNames={['fine-tune-button']} content={'Fine tune'} onClick={fineTune}/>
              <Dropdown defaultDisplayText={'Upload file'} options={FLATFILE_UPLOAD_TYPE} select={setFlatFileUploadType} selected={flatFileUploadType} />
              {flatFileUploadType && importButton}
            </section>
            <h4 className={'input-subtitle'}>{'Fine tune your message by making notes on specific details you’d like to change in the message.'}</h4>
            <section className={'filter-bar'}>
              <PrimaryButton additionalClassNames={['send-all-button']} disabled={hasNoUnsentMessages} content={sendMessagesText} onClick={openSendModal} />
              <RecoverClientsFilter
                filteredRecoverClientMessages={filteredRecoverClientMessages}
                recoverClientMessages={recoverClientMessages}
                resetPage={resetPage}
                filters={filters}
                setFilters={setFilters}
              />
            </section>
          </section>
        </header>
        <section className={'recover-clients-page-content'}>
          <RecoverClientsTable
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            recoverClientMessages={filteredRecoverClientMessages}
            setRecoverClientMessages={setRecoverClientMessages}
            cursor={cursor}
            setCursor={setCursor}
          />
        </section>
      </section>
    </article>
  );
}

export default RecoverClientsManagePage;