import './PrimaryInput.scss';

import React, { FormEvent } from 'react';

export interface PrimaryInputProps {
  text: string,
  setText: ((value: string) => void),
  placeholder: string,
  id?: string,
  disabled?: boolean,
  additionalClassNames?: string[],
  inputType?: string,
  min?: string,
  step?: string,
  pattern?: string
}

function PrimaryInput({ text, setText, placeholder, id = '', disabled = false, additionalClassNames = [], inputType = 'text', min = '', step = '', pattern = '' }: Readonly<PrimaryInputProps>) {
  const onChange = (event: FormEvent<HTMLInputElement>) => setText((event.target as HTMLInputElement).value);
  return <input
    id={id}
    type={inputType}
    className={`primary-input ${additionalClassNames.join(' ')}`}
    placeholder={placeholder}
    value={text}
    onChange={onChange}
    disabled={disabled}
    min={min}
    step={step}
    pattern={pattern}
  />;
}

export default PrimaryInput;