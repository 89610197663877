import './RecoverClientsPreviewFooter.scss';

import React from 'react';

import SubtleButton from '../../../common/buttons/SubtleButton';
import OutlineButton from '../../../common/buttons/OutlineButton';

interface PreviewFooterProps {
  editing: boolean,
  setEditing: (editing: boolean) => void,
  openTuningModal: () => void,
  save: () => void,
}

function RecoverClientsPreviewFooter({ editing, setEditing, openTuningModal, save } : Readonly<PreviewFooterProps>) {
  const saveChanges = () => {
    save();
    setEditing(false);
  }
  const edit = () => setEditing(true);
  const editingButton = editing
    ? <SubtleButton content={'Save changes'} onClick={saveChanges} />
    : <OutlineButton content={'Edit message'} onClick={edit} />;
  
  return (
    <footer className={'preview-footer'}>
      {editingButton}
      <OutlineButton content={'Fine tune'} onClick={openTuningModal} />
    </footer>
  );
}

export default RecoverClientsPreviewFooter;