import './App.scss';

import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { CaseStudyType } from './components/case-studies/CaseStudiesConstants';
import { RequireAuth, RequireNoAuth } from './context/AuthContext';
import LoginViewManager from './components/login/LoginViewManager';
import { useInitialUserFetch } from './hooks/DataHooks';
import LoadingRingPage from './components/common/pages/LoadingRingPage';
import CaseStudiesPage from './components/case-studies/CaseStudiesPage';
import WorkRexPage from './components/wrappers/WorkRexPage';
import FindClientsAboutPage from './components/case-studies/find-clients/FindClientsAboutPage';
import FindClientsSetupPage from './components/case-studies/find-clients/FindClientsSetupPage';
import FindClientsManagePage from './components/case-studies/find-clients/FindClientsManagePage';
import RecoverClientsAboutPage from './components/case-studies/recover-clients/RecoverClientsAboutPage';
import RecoverClientsSetupPage from './components/case-studies/recover-clients/RecoverClientsSetupPage';
import RecoverClientsManagePage from './components/case-studies/recover-clients/RecoverClientsManagePage';
import RecoverClientsMessagePage from './components/case-studies/recover-clients/RecoverClientsMessagePage';
import RecoverClientsSettingsPage from './components/case-studies/recover-clients/RecoverClientsSettingsPage';

function App() {
  const [firstLoad, setFirstLoad] = useState(false);
  useInitialUserFetch(firstLoad, setFirstLoad);
  if (!firstLoad) {
    return <LoadingRingPage color={'#000000'} />;
  }
  return (
    <Router>
      <Routes>
        <Route path={'/'} element={<RequireAuth outlet={<WorkRexPage />} />}>
          <Route path={'home'} element={<CaseStudiesPage type={null} />} />
          <Route path={'find-clients'} element={<CaseStudiesPage type={CaseStudyType.FindNewClients} />} />
          <Route path={'recover-clients'} element={<CaseStudiesPage type={CaseStudyType.RecoverLostClients} />} />
          <Route path={'find-clients/about'} element={<FindClientsAboutPage />} />
          <Route path={'find-clients/setup'} element={<FindClientsSetupPage />} />
          <Route path={'find-clients/manage'} element={<FindClientsManagePage />} />
          <Route path={'recover-clients/about'} element={<RecoverClientsAboutPage />} />
          <Route path={'recover-clients/setup'} element={<RecoverClientsSetupPage />} />
          <Route path={'recover-clients/manage'} element={<RecoverClientsManagePage />} />
          <Route path={'recover-clients/manage/edit'} element={<RecoverClientsMessagePage />} />
          <Route path={'recover-clients/settings'} element={<RecoverClientsSettingsPage />} />
        </Route>
        <Route path={'login'} element={<RequireNoAuth outlet={<LoginViewManager />} />} />
      </Routes>
    </Router>
  );
}

export default App;
