import './FindClientsSetupPage.scss';

import { ReactElement, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import AutoComplete from 'react-google-autocomplete';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import TrashIconButton from '../../common/buttons/composite/TrashIconButton';

import { postCaseStudyRequest } from '../../../apis/CaseStudyApi';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBN79DWVz6nD-htQLf37KfKv3Bnd4cbkxk';

interface GoogleAddressObject {
  formatted_address: string,
}

function FindClientsSetupPage(): ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPlace, setCurrentPlace] = useState('');
  const [places, setPlaces] = useState<string[]>([]);
  const createRequest = async () => {
    setLoading(true);
    const firstName = 'Lance';
    const businessName = 'WorkRex';
    const message = `${firstName} from ${businessName} requested Handwritten Letters for ${places.join(', ')}.`;
    await postCaseStudyRequest(message);
    setLoading(false);
    navigate('/home');
  };
  useEffect(() => {
    if (currentPlace.length > 0 && !places.includes(currentPlace) && places.length < 5) {
      setPlaces([...places, currentPlace]);
      setCurrentPlace('');
    }
  }, [currentPlace])

  const onPlaceSelected = (place: GoogleAddressObject) => setCurrentPlace(place.formatted_address);
  const placesList = places.map((place, index) => {
    const deleteIndex = () => {
      setPlaces(places.filter((_, i) => i !== index));
    }
    return (
      <section className={'place'} key={place}>
        <span className={'index'}>{index + 1}</span>
        <span className={'name'}>{place}</span>
        <TrashIconButton onClick={deleteIndex} />
      </section>
    );
  });
  const disabled = places.length === 0;
  return (
    <article className={'find-clients-setup-page'}>
      <section className={'page'}>
        <header className={'find-clients-setup-page-header'}>
          <h2 className={'title'}>{'Find new clients'}</h2>
          <h3 className={'subtitle'}>{'We just need a few details on what kind of outreach you want and leave the rest to us'}</h3>
        </header>
        <section className={'page-content'}>
          <section className={'section-container'}>
            <h4 className={'input-title'}>{'What area would you like to target?'}</h4>
            <h5 className={'input-subtitle'}>{'Add up to 5 suburbs for this outreach'}</h5>
            <section className={'input-container'}>
              <label className={'input-label'} htmlFor={'number-of-clients-input'}>{'Suburb'}</label>
              <AutoComplete apiKey={GOOGLE_MAPS_API_KEY} onPlaceSelected={onPlaceSelected} placeholder={'Start typing suburb to search'} />
            </section>
            <section className={'places-list'}>
              {placesList}
            </section>
          </section>
          <PrimaryButton
            content={'Create'}
            onClick={createRequest}
            additionalClassNames={['create-request-button']}
            disabled={disabled}
            loading={loading}
          />
        </section>
      </section>
    </article>
  );
}

export default FindClientsSetupPage;