import './HandwrittenLettersTable.scss';

import { useState } from 'react';

import Table, { TableHeaderProps } from '../../../common/tables/Table';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import PaginationBar from '../../../common/tables/PaginationBar';
import { Address, LetterStatus } from '../HandwrittenLettersConstants';
import GreenTag from '../../../common/tags/GreenTag';
import LightPurpleTag from '../../../common/tags/LightPurpleTag';
import DarkPurpleTag from '../../../common/tags/DarkPurpleTag';
import { sendLettersToAddresses } from '../../../../apis/CaseStudyApi';

const { Available, Requested, Complete } = LetterStatus;

const TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Address', key: 'address' },
  { displayText: 'Search value', key: 'searchValue' },
  { displayText: 'Status', key: 'status' },
  { displayText: 'Property value', key: 'propertyValue' },
  { displayText: 'Type', key: 'addressType' },
  { displayText: 'Transaction date', key: 'transactionDate' },
  { displayText: 'Send', key: 'send' },
];


interface HandwrittenLettersTableProps {
  addresses: Address[],
  setAddresses: (addresses: Address[]) => void,
  cursor: number,
  setCursor: React.Dispatch<React.SetStateAction<number>>
};

const getStatusNode = (status: LetterStatus) => {
  switch (status) {
    case Complete:
      return <GreenTag content={status} additionalClassNames={['tag-cell']} />;
    case Requested:
      return <LightPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    case Available:
      return <DarkPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    default:
      return null;
  }
};

const getSendNode = (status: LetterStatus, shouldSend: boolean, sendLetter: () => Promise<void>, loading: boolean) => {
  switch (status) {
    case Complete:
      return <PrimaryButton content={'Sent'} additionalClassNames={['button-cell']} disabled={true} loading={loading} />;
    case Requested:
      if (shouldSend) {
        return <PrimaryButton content={'In progress'} additionalClassNames={['button-cell']} disabled={true} loading={loading} />;
      }
      return <PrimaryButton content={'Send'} additionalClassNames={['button-cell']} onClick={sendLetter} disabled={true} />;
    case Available:
      if (shouldSend) {
        return <PrimaryButton content={'In progress'} additionalClassNames={['button-cell']} disabled={true} loading={loading} />;
      }
      return <PrimaryButton content={'Send'} additionalClassNames={['button-cell']} onClick={sendLetter} loading={loading} />;
    default:
      return null;
  }
};

const HANDWRITTEN_LETTERS_MAX_ROWS = 5;

function HandwrittenLettersTable({ addresses, setAddresses, cursor, setCursor } : Readonly<HandwrittenLettersTableProps> ) {
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  
  const rows = addresses.map(({ _id, address, searchValue, status, propertyValue, transactionDate, addressType, shouldSend }, index) => {
    const addressNode = <section className={'two-cell long-text'}>{address}</section>;
    const searchValueNode = <section className={'one-and-half-cell short-text'}>{searchValue}</section>;
    const statusNode = getStatusNode(status);
    const propertyValueNode = <section className={'one-cell'}>{propertyValue}</section>;
    const transactionDateNode = <section className={'one-cell'}>{transactionDate}</section>;
    const addressTypeNode = <section className={'one-cell'}>{addressType}</section>;
    const loadingSentButton = index === loadingIndex;
    
    const sendClick = async () => {
      setLoadingIndex(index);
      await sendLettersToAddresses([_id]);
      const newAddresses = [...addresses];
      newAddresses[index] = { ...newAddresses[index], shouldSend: true };
      setLoadingIndex(null);
      setAddresses(newAddresses);
    }
    const sendNode = <section className={'one-button-cell'}>{getSendNode(status, shouldSend, sendClick, loadingSentButton)}</section>;
    return {
      cells: [
        { key: 'address', value: addressNode },
        { key: 'searchValue', value: searchValueNode },
        { key: 'status', value: statusNode },
        { key: 'propertyValue', value: propertyValueNode },
        { key: 'addressType', value: addressTypeNode },
        { key: 'transactionDate', value: transactionDateNode },
        { key: 'send', value: sendNode },
      ],
    };
  });
  return (
    <>
      <section className={'handwritten-letters-table-container'}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['handwritten-letters-table']}
            headers={TABLE_HEADERS}
            rows={rows}
            cursor={cursor}
            maxRows={HANDWRITTEN_LETTERS_MAX_ROWS}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={HANDWRITTEN_LETTERS_MAX_ROWS}
            totalRows={rows.length}
          />
        </section>
      </section>
    </>
  )
}

export default HandwrittenLettersTable;

