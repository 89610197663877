import { ReactElement, useState } from 'react';

import ConfirmationModalContent from '../../../common/modals/ConfirmationModalContent';
import Modal from '../../../common/modals/Modal';

interface ConfirmSendLettersModalProps {
  cancel: () => void,
  confirm: () => Promise<void>,
}

function ConfirmSendLettersModal({ cancel, confirm } : ConfirmSendLettersModalProps): ReactElement {
  const [loading, setLoading] = useState(false);
  const confirmSend = async () => {
    setLoading(true);
    await confirm();
    setLoading(false);
  }
  return (
    <Modal>
      <ConfirmationModalContent
        title={'Send to all?'}
        subtitle={'Confirm you’d like to send letters to all remaining addresses.'}
        confirmText={'Confirm'}
        cancelText={'Cancel'}
        cancel={cancel}
        confirm={confirmSend}
        loading={loading}
      />
    </Modal>
  );
}

export default ConfirmSendLettersModal;