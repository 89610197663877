import { FlatfileProvider } from "@flatfile/react";

import { prodFlatFileAuth } from "../../Constants";
import InteractionFlatFileButton from "../../components/people/recipients/common/InteractionFlatFileButton";
import { flatfileConfig, InteractionFlatFileComponentProps } from "./FlatFileConstants";

function InteractionFlatfileImport({ defaultDisplayText, onSubmit }: InteractionFlatFileComponentProps) {
  return (
    <FlatfileProvider
      publishableKey={prodFlatFileAuth.publishableKey}
      config={flatfileConfig}
    >
      <InteractionFlatFileButton defaultDisplayText={defaultDisplayText} onSubmit={onSubmit} />
    </FlatfileProvider>
  )
};

export default InteractionFlatfileImport;