import { ReactElement } from 'react';
import { useNavigate } from 'react-router';

import AboutPage from '../common/AboutPage';
import { CaseStudy, CaseStudyType } from '../CaseStudiesConstants';


function FindClientsAboutPage(): ReactElement {
  const { title, subtitle, previewUrl, setupUrl } = CaseStudy[CaseStudyType.FindNewClients];
  const navigate = useNavigate();
  const onStartClick = () => navigate(setupUrl);
  return <AboutPage title={title} subtitle={subtitle} previewUrl={previewUrl} onStartClick={onStartClick} />
}

export default FindClientsAboutPage;