export enum ConditionText {
  IsExactly = 'Is exactly',
  IsExactlyNot = 'Is exactly not',
  Contains = 'Contains',
  DoesNotContain = 'Does not contain',
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
  IsGreaterThan = 'Is greater than',
  IsLessThan = 'Is less than',
  IsEqual = 'Is equal to',
  IsNotEqual = 'Not equal to',
  IsOneOf = 'Is One Of',
};

export enum ConditionKey {
  IsExactlyKey = 'isExactly',
  IsExactlyNotKey = 'isExactlyNot',
  ContainsKey = 'contains',
  DoesNotContainKey = 'doesNotContain',
  IsEmptyKey = 'isEmpty',
  IsNotEmptyKey = 'isNotEmpty',
  IsGreaterThanKey = 'isGreaterThan',
  IsLessThanKey = 'isLessThan',
  IsEqualKey = 'isEqual',
  IsNotEqualKey = 'isNotEqual',
  IsOneOfKey = 'isOneOf',
};