import './ConfirmMessageDeleteModal.scss';

import React from 'react';

import Modal from '../../../common/modals/Modal';
import ConfirmationModalContent from '../../../common/modals/ConfirmationModalContent';

interface ConfirmMessageDeleteModalProps {
  cancel: () => void,
  confirm: () => void,
};

function ConfirmMessageDeleteModal({ cancel, confirm } : Readonly<ConfirmMessageDeleteModalProps>) {
  return (
    <Modal additionalClassNames={['confirm-variant-deletion-modal']}>
      <ConfirmationModalContent
        title={'Are you absolutely sure?'}
        subtitle={'This action cannot be undone. This will delete this message.'}
        cancel={cancel}
        confirm={confirm}
      />
    </Modal>
  )
};

export default ConfirmMessageDeleteModal;