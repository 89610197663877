import './RecoverClientsPreviewHeader.scss';

import React from 'react';

interface RecoverClientsPreviewHeaderProps {
  name: string,
  target: string,
  schedule?: string,
  messageNumber: number,
  totalMessages: number,
};

function RecoverClientsPreviewHeader({ name, target, schedule = '', messageNumber, totalMessages } : Readonly<RecoverClientsPreviewHeaderProps>) {
  return (
    <header className={'preview-header'}>
      <span className={'preview-header-label'}>
        {'Send to'}
      </span>
      <span className={'preview-name'}>{name}</span>
      <span className={'preview-target'}>{target}</span>
      {schedule.length > 0 && <span className={'preview-schedule'}>{`Scheduled send: ${schedule}`}</span>}
      <span className={'preview-number'}>{`Showing (${messageNumber}/${totalMessages})`}</span>
    </header>
  );
}

export default RecoverClientsPreviewHeader;