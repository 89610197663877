import './SmsPreview.scss';

import React, { MutableRefObject } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';

interface SmsPreviewProps {
  content: string,
  editing: boolean,
  contentRef: MutableRefObject<string>
};

function SmsPreview({ content, editing, contentRef } : Readonly<SmsPreviewProps>) {
  const handleChange = (event: ContentEditableEvent) => {
    contentRef.current = event.target.value;
  }
  if (editing) {
    return (
      <ContentEditable html={contentRef.current} onChange={handleChange} className={'sms-preview'} />
    );
  }
  return (
    <section className={'sms-preview'} dangerouslySetInnerHTML={{ __html: content }} />
  );
};
export default SmsPreview;