export enum Channel {
  SMS = 'SMS',
  Email = 'Email'
}

// For Address Filtering
export enum FlatFileTypeText {
  ContactsText = 'Contacts',
  InteractionsText = 'Interactions',
};

export enum FlatFileTypeKey {
  ContactsKey = 'contacts',
  InteractionsKey = 'interactions',
}

const { ContactsText, InteractionsText } = FlatFileTypeText;
const { ContactsKey, InteractionsKey } = FlatFileTypeKey;

export const FLATFILE_UPLOAD_TYPE = [
  { displayText: ContactsText, value: ContactsKey },
  { displayText: InteractionsText, value: InteractionsKey },
];

export enum MessageStatus {
  Delivered = 'Delivered',
  NotSent = 'Not sent',
};

export interface RecoverClientMessage {
  _id: string,
  recipientName: string,
  channel: Channel,
  sendTo: string,
  sendDate: number,
  messageStatus: MessageStatus,
  trigger: string,
  triggerDate: number,
  message: string,
  creationDate: number,
  businessId: null,
  deletionDate: null,
}

export const TEST_RECOVER_CLIENT_MESSAGES = [
  {
    _id: '123',
    recipientName: 'Andrew Zhang',
    channel: Channel.SMS,
    sendTo: '0434729991',
    sendDate: Date.now(),
    messageStatus: MessageStatus.NotSent,
    trigger: 'Moved to \'priority to reactivate\' segment',
    triggerDate: 1716904802000,
    message: 'Hey Lance, it\'s been a while since you last visited in 2021.',
    creationDate: Date.now(),
    businessId: null,
    deletionDate: null,
  },
  {
    _id: '234',
    recipientName: 'Andrew Fog',
    channel: Channel.SMS,
    sendTo: '0434729991',
    sendDate: Date.now(),
    messageStatus: MessageStatus.NotSent,
    trigger: 'Moved to \'priority to reactivate\' segment',
    triggerDate: Date.now(),
    message: 'Hey Lance, it\'s been a while since you last visited in 2021.',
    creationDate: Date.now(),
    businessId: null,
    deletionDate: null,
  },
  {
    _id: '234',
    recipientName: 'Andrew Fog',
    channel: Channel.SMS,
    sendTo: '0434729991',
    sendDate: Date.now(),
    messageStatus: MessageStatus.NotSent,
    trigger: 'Moved to \'priority to reactivate\' segment',
    triggerDate: Date.now(),
    message: 'Hey Lance, it\'s been a while since you last visited in 2021.',
    creationDate: Date.now(),
    businessId: null,
    deletionDate: null,
  },
  {
    _id: '234',
    recipientName: 'Andrew Fog',
    channel: Channel.SMS,
    sendTo: '0434729991',
    sendDate: Date.now(),
    messageStatus: MessageStatus.NotSent,
    trigger: 'Moved to \'priority to reactivate\' segment',
    triggerDate: Date.now(),
    message: 'Hey Lance, it\'s been a while since you last visited in 2021.',
    creationDate: Date.now(),
    businessId: null,
    deletionDate: null,
  },
  {
    _id: '234',
    recipientName: 'Andrew Fog',
    channel: Channel.SMS,
    sendTo: '0434729991',
    sendDate: Date.now(),
    messageStatus: MessageStatus.NotSent,
    trigger: 'Moved to \'priority to reactivate\' segment',
    triggerDate: Date.now(),
    message: 'Hey Lance, it\'s been a while since you last visited in 2021.',
    creationDate: Date.now(),
    businessId: null,
    deletionDate: null,
  },
  {
    _id: '234',
    recipientName: 'Andrew Fog',
    channel: Channel.SMS,
    sendTo: '0434729991',
    sendDate: Date.now(),
    messageStatus: MessageStatus.NotSent,
    trigger: 'Moved to \'priority to reactivate\' segment',
    triggerDate: Date.now(),
    message: 'Hey Lance, it\'s been a while since you last visited in 2021.',
    creationDate: Date.now(),
    businessId: null,
    deletionDate: null,
  }
]