import { FlatfileProvider } from "@flatfile/react";

import { prodFlatFileAuth } from "../../Constants";
import { AddressFlatFileComponentProps, flatfileConfig } from "./FlatFileConstants";
import AddressFlatFileButton from "../../components/case-studies/common/AddressFlatFileButton";

function AddressFlatfileImport({ setAddresses } : AddressFlatFileComponentProps) {
  return (
    <FlatfileProvider
      publishableKey={prodFlatFileAuth.publishableKey}
      config={flatfileConfig}
    >
      <AddressFlatFileButton setAddresses={setAddresses} />
    </FlatfileProvider>
  )
};

export default AddressFlatfileImport;