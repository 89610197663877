export enum CaseStudyType {
  FindNewClients = 'Find new clients',
  RecoverLostClients = 'Retain lost clients',
};

interface CaseStudy {
  type: CaseStudyType,
  title: string,
  subtitle: string,
  previewUrl: string,
  setupUrl: string,
}

export const CaseStudy: Record<CaseStudyType, CaseStudy> = {
  [CaseStudyType.FindNewClients]: {
    type: CaseStudyType.FindNewClients,
    title: 'Find new clients',
    subtitle: 'Personalised handwritten letters, crafted and delivered to potential recipients aimed to convert into new customers',
    previewUrl: 'https://www.youtube.com/embed/ZtYaqKAc5mA?si=ifgER9mx0h8w_7iO',
    setupUrl: '/find-clients/setup',
  },
  [CaseStudyType.RecoverLostClients]: {
    type: CaseStudyType.RecoverLostClients,
    title: 'Recover lost clients',
    subtitle: 'Create promotions aimed to re-engage existing customers. Promotions are created based on historic purchases',
    previewUrl: 'https://www.youtube.com/embed/ZtYaqKAc5mA?si=ifgER9mx0h8w_7iO',
    setupUrl: '/recover-clients/setup',
  },
}